import React from 'react'
import { TabTitle } from '../../utils/TabTitle'

const PageNotFound = () => {
    TabTitle("404");
    return (
        <div>Error 404: page not found</div>
    )
}

export default PageNotFound