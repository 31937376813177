import { TabTitle } from "../../utils/TabTitle"
import "./Home.css"
import KaboutersLogo from "../../assets/banschildjes/kabouters.svg"
import JongknapenLogo from "../../assets/banschildjes/jongknapen.svg"
import KnapenLogo from "../../assets/banschildjes/knapen.svg"
import JonghernieuwersLogo from "../../assets/banschildjes/jonghernieuwers.svg"
import HernieuwersLogo from "../../assets/banschildjes/hernieuwers.svg"
import LeidingLogo from "../../assets/banschildjes/leiding.svg"

const Home = () => {
    TabTitle("");

    return (
        <div>
            <div className="banWrapper">
                <img src={KaboutersLogo} alt="Banschildje kabouters" className="banlogo" />
                <div>
                    <h1>Kabouters</h1>
                    <h2>(1e, 2e, 3e leerjaar)</h2>
                    <p>De Kabouters moeten KSA nog leren kennen, elke week een beetje meer! Zij zijn de jongste leden van onze groep. KSA betekent voor hen spelen, spelen en nog eens spelen. Ze kunnen zich op elke activiteit verwonderen over de nieuwe ervaringen die ze opdoen. Hun levendige fantasie zorgt ervoor dat ze op reis kunnen naar eender waar, met eender wie, om eender welk probleem op te lossen. Leiders zijn tijdens die reizen hun troost en toeverlaat.</p>
                </div>
            </div>
            <div className="banWrapper">
                <img src={JongknapenLogo} alt="Banschildje jongknapen" className="banlogo" />
                <div>
                    <h1>Jongknapen</h1>
                    <h2>(4e, 5e, 6e leerjaar)</h2>
                    <p>De Jongknapen vinden in KSA vooral actie en spanning. Ze mogen zelf al eens het initiatief nemen om de activiteiten wat op te vullen, al houdt de leiding steeds een oogje in het zeil. Ze zijn de waaghalzen van KSA die graag in competitie treden met elkaar. In de jeugdbeweging mogen ze echt bij de groep horen en nieuwe vrienden leren kennen.</p>
                </div>
            </div>
            <div className="banWrapper">
                <img src={KnapenLogo} alt="Banschildje knapen" className="banlogo" />
                <div>
                    <h1>Knapen</h1>
                    <h2>(1e & 2e middelbaar)</h2>
                    <p>Bij de overstap naar het middelbaar kom je bij de Knapen. Ook bij KSA betekent dat een berg nieuwe activiteiten. Vanaf deze leeftijd ben je verplicht om met de fiets te komen zodat er langere-afstandspellen kunnen gespeeld worden.</p>
                </div>
            </div>
            <div className="banWrapper">
                <img src={JonghernieuwersLogo} alt="Banschildje jonghernieuwers" className="banlogo" />
                <div>
                    <h1>Jonghernieuwers</h1>
                    <h2>(3e & 4e middelbaar)</h2>
                    <p>KSA wordt voor de Jonghernieuwers een plaats met veel vrienden. Ze willen soms eens stilzitten om wat gezellig te babbelen, maar trekken er even graag samen op uit om de meest uiteenlopende stunts te beleven. De leid(st)ers zetten activiteiten op die een uitdaging vormen voor de leden en hen uitnodigen om samen te werken om hun doel te bereiken. Er wordt al heel wat verantwoordelijkheid doorgespeeld naar de leden en de groep mag geregeld zelf één en ander organiseren. In KSA mogen ze zichzelf zijn, los van de doordeweekse verplichtingen en verwachtingen.</p>
                </div>
            </div>
            <div className="banWrapper">
                <img src={HernieuwersLogo} alt="Banschildje hernieuwers" className="banlogo" />
                <div>
                    <h1>Hernieuwers</h1>
                    <h2>(5e middelbaar)</h2>
                    <p>De Hernieuwers zijn in hun laatste jaar als lid volop bezig met hun "opleiding" tot volwaardig leider. Zij hebben speciale activiteiten en ook tradities zoals een 5-daagse waarop ze met de fiets van Deerlijk naar de kampplaats fietsen, hoever deze ook mag liggen.</p>
                </div>
            </div>
            <div className="banWrapper">
                <img src={LeidingLogo} alt="Banschildje leiding" className="banlogo" />
                <div>
                    <h1>Leiding</h1>
                    <p>Men kiest ervoor om een uitgebreider engagement op te nemen voor de werking en de verantwoordelijkheid te dragen over een bepaalde leeftijdsgroep. Ook wordt een engagement verwacht bij alle verschillende evenementen. Elke leider wordt aan een ban (leeftijdsgroep) toegewezen. Gedurende het jaar, en op kamp, voorzien ze activiteiten voor hun ban. Op een vaste dag in de week vergadert de leiding over de activiteit die ze dat weekend zullen organiseren. Per leeftijdsgroep wordt ook een banleider aangesteld.</p>
                </div>
            </div>
        </div>
    )
}

export default Home