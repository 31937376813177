import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import instagramIcon from '../assets/logos/Instagram.svg'
import facebookIcon from '../assets/logos/Facebook.svg'
import logo from '../assets/logos/KSA-Deerlijk.svg'
import './Navbar.css'
import { useEffect } from 'react';


const PageNavbar = () => {
    useEffect(() => {
        let nav = document.querySelector('.navbar');

        if (nav) {
            var scrollNumberLast = 0;
            window.addEventListener('scroll', () => {
                let scrollNumber = window.scrollY;

                if (scrollNumber <= 20) {
                    nav?.classList.remove('scrolled-down');
                    nav?.classList.remove('scrolled-up');
                }
                else if (scrollNumber < scrollNumberLast) {
                    nav?.classList.remove('scrolled-down');
                    nav?.classList.add('scrolled-up');
                }
                else {
                    nav?.classList.remove('scrolled-up');
                    nav?.classList.add('scrolled-down');
                }

                scrollNumberLast = scrollNumber;
            });
        }
    }, [])

    const setAsActiveItem = (event: any) => {
        clearAllItems();

        event.currentTarget.classList.add("active");
    }

    const clearAllItems = () => {
        let navLinks = document.querySelectorAll(".nav-link");
        navLinks.forEach(navLink => navLink.classList.remove("active"));
    }

    return (
        <Navbar bg="light" expand="sm" className='sticky-top'>
            <Container>
                <Link to="/" className="navbar-brand" onClick={clearAllItems}><img src={logo} alt='KSA Deerlijk logo' id='logo' /></Link>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto">
                        <Link to="/contact" className='nav-link' onClick={setAsActiveItem}>Contact</Link>
                        <Link to="/calendars" className='nav-link' onClick={setAsActiveItem}>Jaarkalenders</Link>
                        <Link to="/pictures" className='nav-link' onClick={setAsActiveItem}>Foto's</Link>
                    </Nav>
                    <Nav className="ms-auto">
                        <a href="http://facebook.ksadeerlijk.be" className='nav-link'><img src={facebookIcon} className="socialIcon" alt="facebook icon" /></a>
                        <a href="http://instagram.ksadeerlijk.be" className='nav-link'><img src={instagramIcon} className="socialIcon" alt="instagram icon" /></a>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default PageNavbar;
